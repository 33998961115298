<template>
  <b-modal
    visible
    scrollable
    centered
    size="md"
    :title="'Создание пациента'"
    hide-footer
    @hidden="onClose"
    @ok="onClose"
  >
    <div class="policy-delete">
      <custom-scrollbar>
        <div
          class="crm-container policy-container"
        >
          <div class="description">
            Вы хотите создать пользователя для:
          </div>
          <div class="crm-wrapper-buttons">
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              @click="addPatientForIndemnity"
            >
              Только ГП
            </b-button>
            <b-button
              variant="outline-primary"
              :type="$const.PRIMARY_BUTTON"
              size="sm"
              class="ml-3"
              @click="addPatientForDoctis"
            >
              для Доктис
            </b-button>
          </div>
        </div>
      </custom-scrollbar>
    </div>
  </b-modal>
</template>

<script>
import { mixinRoles } from '@/mixins';
import { uiService } from '@/services/core/ui';
import { MODALS } from '@/services/core/ui/modals.const';
import { CREATE_PATIENT_MODE } from '@/helpers/consts';

export default {
  name: 'PatientCreateModal',
  mixins: [mixinRoles],
  props: {
    modalName: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      isOpen: true,
      isDeleting: false,
    };
  },
  methods: {
    async addPatientForIndemnity() {
      uiService.showModal(MODALS.PATIENT_CREATE, {
        props: {
          mode: CREATE_PATIENT_MODE.ForIndemnity,
        },
        actions: {
          success: () => {
            this.$emit('success');
            this.onClose();
          },
        },
      });

      // this.onClose();
    },
    async addPatientForDoctis() {
      uiService.showModal(MODALS.PATIENT_CREATE, {
        props: {
          mode: CREATE_PATIENT_MODE.ForDoctis,
        },
        actions: {
          success: () => {
            this.$emit('success');
            this.onClose();
          },
        },
      });

      // this.onClose();
    },

    onClose() {
      this.$emit('input', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-container {
  flex-direction: column;
  align-items: center;
}
.crm-wrapper-buttons {
  margin-top: 35px;
}
.description {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
}
</style>
